import React from "react";
import { Seo } from "../components/Seo";

const ThankyouPage = () => {
  return (
    <main>
      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-8 text-center">
          <h1 className="font-black text-3xl lg:text-4xl mb-4">Awesome 🙌 🔥</h1>
          <p className="font-black text-2xl mb-4">We're excited to find out more about your brand and plans.</p>
          <p className="text-xl mb-4">Your dedicated Brand Specialist will send over an email very shortly and give you a call in the next 2 business days. There will be a calendar link in the email so you can book a call or Google meet straight away for a time that works best for you.</p>
        </div>
      </section>
      <section className="bg-white py-24">
        <div className="xl:container mx-auto px-4 grid lg:grid-cols-3 gap-x-12 gap-y-8 lg:gap-y-16">
          <div className="lg:col-span-3 text-center lg:text-left">
            <p className="text-4xl md:text-5xl font-black">Where you will <span className="text-sourci-teal">find us.</span></p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-pink py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-pink mb-2">Head Office</p>
            <p className="text-xl text-black font-black mb-4">Melbourne, Australia</p>
            <p className="text-xl text-black mb-4">1300 SOURCI</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">Level 5, 600 Church St, <br />Cremorne VIC 3121<br />Australia</p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-teal py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-teal mb-2">Shenyang Office</p>
            <p className="text-xl text-black font-black mb-4">China</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">Room 614-615, Building 4, <br />Hongxing Guoji, No.33<br />Xiobeier Road, Tiexi District<br />Shenyang, China</p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-orange py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-orange mb-2">Surat Office</p>
            <p className="text-xl text-black font-black mb-4">India</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">118, Green Plaza <br />Near D-Mart, Mota Varachha,<br />Surat, Gujarat,<br />India, 394101</p>
          </div>
        </div>
      </section>
    </main>
  )
} 

export default ThankyouPage;

export function Head() {
  return (
    <Seo title="Contact Us | Sourci"
      canonical="/thankyou" />
  )
}